export const geoApiOptions = {
  method: "GET",
  headers: {
    "X-RapidAPI-Key": "3a74279103msh7070c0abc4c1b67p1778bajsn40313b984272",// enter your rapid api key here
    "X-RapidAPI-Host": "wft-geo-db.p.rapidapi.com",
  },
};
export const GEO_API_URL = "https://wft-geo-db.p.rapidapi.com/v1/geo";

export const WEATHER_API_URL = "https://api.openweathermap.org/data/2.5";
export const WEATHER_API_KEY = "4661b7681cee51a5f9132031ff09d549"; // enter your key from openweather API
